<template>
  <div class="main-container-courses">
    <div class="container-courses">
      <div class="courses-row">
        <a class="course-item online" href="/app/curso-online/login">
          <div class="courses-information-tag">
            <span class="plazas-disponibles-text">Plazas disponibles</span>
            <span class="title-text">CURSO EUREKERS</span>
            <span class="date-text">Edición 42ª</span>
            <span class="online-type">A DISTANCIA</span>
          </div>
        </a>
        <a class="course-item argentina" href="/app/store/course/form-waitlist/argentina">
          <div class="courses-information-tag">
            <span class="plazas-disponibles-text">Plazas disponibles</span>
            <span class="title-text">CURSO EUREKERS</span>
            <span class="date-text">ARGENTINA</span>
            <span class="presential-type">PRESENCIAL</span>
          </div>
        </a>
        <a class="course-item espana" href="/app/store/course/form-waitlist/espana">
          <div class="courses-information-tag">
            <span class="plazas-disponibles-text">Plazas disponibles</span>
            <span class="title-text">CURSO EUREKERS</span>
            <span class="date-text">ESPAÑA</span>
            <span class="presential-type">PRESENCIAL</span>
          </div>
        </a>
        <a class="course-item mexico" href="/app/store/course/form-waitlist/mexico">
          <div class="courses-information-tag">
            <span class="plazas-disponibles-text">Plazas disponibles</span>
            <span class="title-text">CURSO EUREKERS</span>
            <span class="date-text">MEXICO</span>
            <span class="presential-type">PRESENCIAL</span>
          </div>
        </a>
        <a class="course-item chile" href="/app/store/course/form-waitlist/chile">
          <div class="courses-information-tag">
            <span class="plazas-disponibles-text">Plazas disponibles</span>
            <span class="title-text">CURSO EUREKERS</span>
            <span class="date-text">CHILE</span>
            <span class="presential-type">PRESENCIAL</span>
          </div>
        </a>
      </div>
    </div>
    <p class="title-section">¿Quieres ser tu propio <span class="span-in-p"> asesor financiero </span>?</p>
    <div class="information-container">
      <div class='information-row'>
        <div class="left-side">
          <p>Mediante unas reglas lógicas y una psicología adecuada, todos podemos llegar a convertirnos en exitosos inversores de bolsa. <strong>Este curso de bolsa te enseñará a ser tu propio asesor financiero</strong>, conocerás la estrategia básica del método de inversión Eurekers y adquirirás los <strong>conocimientos prácticos necesarios para operar en la zona euro.</strong></p>
          <p>Nuestro curso de bolsa está dirigido a todo tipo de personas que quieran aprender a invertir en bolsa, sea cual sea su formación y experiencia previa. Para aquellas personas que no hayan tenido antes contacto con el mundo bursátil, este curso le ofrece una base sólida sobre cómo invertir en bolsa para poder realizar inversiones rentables. Para las personas que ya tienen experiencia, el curso les enseñará una forma de invertir desde una perspectiva totalmente lógica.</p>
          <p>Una vez finalizado el curso, se pondrán a disposición de cada alumno las <strong>herramientas necesarias para que puedas poner en práctica los conocimientos adquiridos</strong> en el aula. Además, contarás con la ayuda personalizada de nuestro servicio de Atención al Cliente para resolver cualquier duda.</p>
          <img src="/sites/all/themes/Porto_sub/img/home-cta-call.png">
          <h2>AVAL DE CONFIANZA</h2>
            <p><span class="dot-list">·</span> <strong>Único curso de bolsa donde los alumnos ganan</strong></p>
            <p><span class="dot-list">·</span> Si transcurrido el 25% del curso, este no cumple sus expectativas le devolvemos el 100% del importe del curso.</p>
            <p><span class="dot-list">·</span> La valoración de nuestros alumnos, con una nota media del 9.7, explica porque <strong>el 70% de los asistentes, lo hacen por recomendación de antiguos alumnos.</strong> Consulte las <a href="/opiniones-de-cursos-de-bolsa" target="_blank" title="Opiniones alumnos Eurekers">opiniones sobre nuestros cursos de bolsa.</a></p>
            <p><span class="dot-list">·</span> Visita el área donde nuestros alumnos cuentan el testimonio de la rentabilidad de sus <a href="/testimonios-rentabilidad-alumnos-cursos-de-bolsa" target="_blank" title="Testimonio de rentabilidad en cartera de inversión auditada">carteras de inversión auditadas.</a></p>
            <p><span class="dot-list">·</span> Nuestros cursos los imparte <a href="https://www.joseantoniomadrigal.com/" target="_blank" title="Curso de bolsa José Antonio Madrigal para Eurekers">José Antonio Madrigal,</a> gestor de fondos y operador con más de 20 años de experiencia en el mercado bursátil.</p>
            <p><span class="dot-list">·</span> En la actualidad, Eurekers reúne aproximadamente a 4.000 personas que comparten experiencias y conocimiento continuamente.</p>
          <h2>NIVEL DE DIFICULTAD</h2>
          <p>El Curso de Bolsa es <strong> adecuado para todo tipo de personas</strong>, sea cual sea su formación previa. De este modo, el curso es válido para invertir en bolsa para principiantes. Todos nuestros alumnos alcanzan los objetivos marcados.</p>
        </div>
        <div class="right-side">
          <h2 style="margin-top: 0;">NUESTROS CURSOS DE BOLSA</h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Z-5VvDC5QUM?si=EaMoJgWO9Ex3lLxO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <h2>CONTENIDOS</h2>
          <ul class="numerada">
            <li>Psicología en la inversión efectiva.</li>
            <li>Gestión del riesgo por operación.</li>
            <li>Gestión global de cartera.</li>
            <li>Método básico de Inversión de Eurekers. Práctica.</li>
            <li>Uso correcto de Stops. Práctica.</li>
            <li>Volumen de negocio. Práctica.</li>
            <li>Inversión mínima y máxima por operación. Práctica.</li>
            <li>Barras envolventes. Rapidez de onda. Práctica.</li>
            <li>Selección de los mejores valores. Práctica.</li>
            <li>Herramientas eficaces de inversión.</li>
          </ul>
          <h2>OBJETIVOS</h2>
          <p><span class="dot-list">·</span> Aprender a controlar los impulsos de la mente.</p>
          <p><span class="dot-list">·</span> Saber localizar en qué valores invertir y cuáles no.</p>
          <p><span class="dot-list">·</span> Detectar malas carteras de inversión y convertirlas en buenas.</p>
          <p><span class="dot-list">·</span> Controlar los riesgos de una forma eficaz.</p>
          <p><span class="dot-list">·</span> Ser autosuficiente en el mundo bursátil.</p>
          <h2>DURACIÓN</h2>
          <p><strong>Horas lectivas:</strong> 12 horas.</p>
          <p>Para garantizar que todos los alumnos alcanzan objetivos marcados, cuando finalicen las clases de bolsa y de forma totalmente gratuita, aquellos alumnos que no hayan comprendido alguno de los aspectos tendrán la posibilidad de quedarse en clase para resolver sus dudas.</p>
        </div>
      </div>
    </div>
    <div style="text-align: center; height: 150px;">
      <h2 style="color: #00add9;">TESTIMONIOS DE ALUMNOS EN NUESTRO CURSO DE BOLSA</h2>
      <a href="/opiniones-online" target="_blank" class="testimonios-link-button">Ver más testimonios de cursos de bolsa</a>
    </div>
  </div>

</template>



<script>

export default {
  name: 'Courses',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
}
</script>



<style lang="scss" scoped>
  .container-courses{
    height: 35vh;
    display: flex;
    justify-content: center;
  }

  .courses-row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 75%;
  }

  .course-item{
    cursor: pointer;
    width: 32%;
    height: 49%;
    border: 1px solid white;
    position: relative;
    margin-left: 5px;
    border-radius: 5px;
  }

  .online{
    background-image: url('https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/cursos_de_bolsa/other/proximos-cursos/curso-online.jpg');
    background-size: cover;
    background-position: center;
  }
  .argentina{
    background-image: url('https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/cursos_de_bolsa/other/proximos-cursos/proximoscursos-argentina.png');
    background-size: cover;
    background-position: center;
  }
  .espana{
    background-image: url('https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/cursos_de_bolsa/other/proximos-cursos/proximoscursos-españa.png');
    background-size: cover;
    background-position: center;
  }
  .mexico{
    background-image: url('https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/cursos_de_bolsa/other/proximos-cursos/proximoscursos-mexico.png');
    background-size: cover;
    background-position: center;
  }
  .chile{
    background-image: url('https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/cursos_de_bolsa/other/proximos-cursos/proximoscursos-chile.png');
    background-size: cover;
    background-position: center;
  }

  .courses-information-tag{
    position: absolute;
    bottom: 15px;
    background-color: #000010;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 27px;
    padding-right: 20px;
    font-size: 14px;
    font-family: 'open sans', sans-serif;
    width: 60%;
    line-height: 20px;
  }

  .plazas-disponibles-text{
    font-family: 'Open Sans', sans-serif;
    display: block;
    color: #27c43b;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .title-text{
    font-family: 'Open Sans', sans-serif;
    display: block;
    color: white;
    font-size: 20px;
    font-weight: 900;
  }

  .date-text{
    font-family: 'Open Sans', sans-serif;
    display: block;
    color: white;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .online-type{
    font-family: 'Open Sans', sans-serif;
    display: block;
    background-color: #00add9;
    font-weight: bold;
    font-size: 12px;
    color: #0f0f1a;
    width: fit-content;
    padding: 1px;
    line-height: 12px;
  }

  .presential-type{
    font-family: 'Open Sans', sans-serif;
    display: block;
    background-color: #f29a04;
    font-weight: bold;
    font-size: 12px;
    color: #0f0f1a;
    width: fit-content;
    padding: 1px;
    line-height: 12px;
  }
  .information-row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
  }
  .main-container-courses{
    overflow: scroll !important;
    background-color: var(--color-background);
  }
  .title-section{
    margin-top: 33px;
    text-align: center;
    font-size: 35px;
    font-weight: 100;
  }
  .span-in-p{
    background-color: #00add9;
    color: white;
    font-weight: 700;
  }
  .left-side{
    width: 45%;
  }
  .right-side{
    width: 45%;
  }
  .numerada {
    list-style: none;
    counter-reset: item;
  }

  .numerada li {
    counter-increment: item;
    margin-bottom: 5px;
  }

  .numerada li::before {
    content: counter(item) ". ";
    font-weight: bold;
  }
  .information-container{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color-background);
  }
  .information-row h2{
    color: #00add9;
  }
  .dot-list{
    font-size: 20px;
    font-weight: bold;
  }
  a{
    color: #00add9;
  }
  .testimonios-link-button{
    color: white;
    background-color: #00add9;
    padding: 15px;
    border-radius: 15px;
    font-weight: bold;
  }
  .testimonios-link-button:hover{
    cursor: pointer;
  }

  p, li {
    color: var(--color-text-primary);
  }
</style>
